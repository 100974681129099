import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../app/api";

export const userLogin = createAsyncThunk("auth/login", async ({ username, password }, { rejectWithValue }) => {
  try {
    const { data } = await api.post(`api/login`, { username, password });

    if (
      !data?.roles?.includes("ROLE_DATA_OWNER") &&
      !data?.roles?.includes("ROLE_REGIONAL_SALES_MANAGER") &&
      !data?.roles?.includes("ROLE_NATIONAL_SERVICE_MANAGER") &&
      !data?.roles?.includes("ROLE_SERVICE_COORDINATOR")
    )
      throw Error(`Access denied!`);

    // store user's token in local storage
    localStorage.setItem("USER_DETAILS", JSON.stringify(data));
    if (data?.roles.length) localStorage.setItem("USER_CURRENT_ROLE", JSON.stringify(data?.roles[0]));

    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else if (error.message) {
      return rejectWithValue(error.message);
    } else {
      return rejectWithValue("Invalid username/password");
    }
  }
});
