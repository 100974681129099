import { createSlice } from "@reduxjs/toolkit";
import {
  getCustomersWithServiceTickets,
  getServiceTicketsByStatusList,
  getServiceTicketsForTheLastXDays,
  readServiceTicket,
  createServiceTicket,
  updateServiceTicket,
  readServiceTicketMessagesByServiceTicketId,
  addTextMessageToServiceTicket,
  readServiceTimeLog,
  getServiceTicketsByUserId,
  getSvcTktDetailsForWhatsappMessage,
} from "./actions";

const initialState = {
  loading: false,
  serviceTicketsByStatusList: {
    loading: false,
    data: [],
    error: null,
  },
  customersWithServiceTickets: {
    loading: false,
    data: [],
    error: null,
  },
  allSvcTickets: {
    loading: false,
    data: [],
    error: null,
  },
  serviceTicketById: {},
  serviceTicketMessages: {
    loading: false,
    data: [],
    error: null,
  },
  serviceTimeLogs: {
    loading: false,
    data: [],
    error: null,
  },
  whatsappMessages: {
    loading: false,
    data: [],
    error: null,
  },
  serviceTicketsByUserId: {
    loading: false,
    data: [],
    error: null,
  },
  error: null,
  success: false,
};
const authSlice = createSlice({
  name: "serviceTickets",
  initialState,
  reducers: {},
  extraReducers: {
    [getServiceTicketsByStatusList.pending]: (state) => {
      state.loading = true;
      state.serviceTicketsByStatusList.loading = true;
      state.serviceTicketsByStatusList.error = null;
    },
    [getServiceTicketsByStatusList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceTicketsByStatusList.loading = false;
      state.serviceTicketsByStatusList.data = payload;
    },
    [getServiceTicketsByStatusList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceTicketsByStatusList.loading = false;
      state.serviceTicketsByStatusList.error = payload;
    },
    [getCustomersWithServiceTickets.pending]: (state) => {
      state.loading = true;
      state.customersWithServiceTickets.loading = true;
      state.customersWithServiceTickets.error = null;
    },
    [getCustomersWithServiceTickets.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customersWithServiceTickets.loading = false;
      state.customersWithServiceTickets.data = payload;
    },
    [getCustomersWithServiceTickets.rejected]: (state, { payload }) => {
      state.loading = false;
      state.customersWithServiceTickets.loading = false;
      state.customersWithServiceTickets.error = payload;
    },
    [getServiceTicketsForTheLastXDays.pending]: (state) => {
      state.loading = true;
      state.allSvcTickets.loading = true;
      state.allSvcTickets.error = null;
    },
    [getServiceTicketsForTheLastXDays.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.allSvcTickets.loading = false;
      state.allSvcTickets.data = payload;
    },
    [getServiceTicketsForTheLastXDays.rejected]: (state, { payload }) => {
      state.loading = false;
      state.allSvcTickets.loading = false;
      state.allSvcTickets.error = payload;
    },
    [readServiceTimeLog.pending]: (state) => {
      state.serviceTimeLogs.loading = true;
    },
    [readServiceTimeLog.fulfilled]: (state, { payload }) => {
      state.serviceTimeLogs.loading = false;
      state.serviceTimeLogs.data = payload;
    },
    [readServiceTimeLog.rejected]: (state) => {
      state.serviceTimeLogs.loading = false;
    },
    [readServiceTicket.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [readServiceTicket.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.serviceTicketById = action.payload;
    },
    [readServiceTicket.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [getSvcTktDetailsForWhatsappMessage.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSvcTktDetailsForWhatsappMessage.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.whatsappMessages = action.payload.Message;
    },
    [getSvcTktDetailsForWhatsappMessage.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [createServiceTicket.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createServiceTicket.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [createServiceTicket.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [updateServiceTicket.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateServiceTicket.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [updateServiceTicket.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [readServiceTicketMessagesByServiceTicketId.pending]: (state) => {
      state.serviceTicketMessages.loading = true;
      state.error = null;
    },
    [readServiceTicketMessagesByServiceTicketId.fulfilled]: (state, { payload }) => {
      state.serviceTicketMessages.loading = false;
      state.serviceTicketMessages.data = payload;
    },
    [readServiceTicketMessagesByServiceTicketId.rejected]: (state, { payload }) => {
      state.serviceTicketMessages.loading = false;
      state.error = payload;
    },
    [addTextMessageToServiceTicket.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addTextMessageToServiceTicket.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [addTextMessageToServiceTicket.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getServiceTicketsByUserId.pending]: (state) => {
      state.serviceTicketsByUserId.loading = true;
      state.error = null;
    },
    [getServiceTicketsByUserId.fulfilled]: (state, { payload }) => {
      state.serviceTicketsByUserId.loading = false;
      state.serviceTicketsByUserId.data = payload;
    },
    [getServiceTicketsByUserId.rejected]: (state, { payload }) => {
      state.serviceTicketsByUserId.loading = false;
      state.error = payload;
    },
  },
});
export default authSlice.reducer;
