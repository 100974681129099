import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Autocomplete, TextField, Button, Stack, InputAdornment, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { exportToCsv } from "../../utils/utilFunctions";
import { getServiceTicketsForTheLastXDays, getSvcTktDetailsForWhatsappMessage } from "../../features/servicetickets/actions";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { readAsyncStorageValues } from "../../features/common/actions";
import ContentCopy from "@mui/icons-material/ContentCopy";
import { readMachinesByCustomerId } from "../../features/customers/actions";

const AllSvcTicketForLastXDays = () => {
  const dispatch = useDispatch();
  const { serviceTicketsForCustomer } = useSelector((state) => state.customers);
  const { allSvcTickets, serviceTicketById, whatsappMessages } = useSelector((state) => state.serviceTickets);
  const [days, setDays] = useState("30");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const { userInfo, userCurrentRole } = useSelector((state) => state.auth);
  const { asyncStorageValues } = useSelector((state) => state.common);
  useEffect(() => {
    if (userInfo && Object.keys(userInfo).length) dispatch(readAsyncStorageValues());
  }, [dispatch, userInfo]);
  useEffect(() => {
    const fetchCustomerServiceTickets = async () => {
      try {
        await dispatch(getServiceTicketsForTheLastXDays(days));
      } catch (error) {
        console.error("Error fetching service tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    if (days) {
      setLoading(true);
      fetchCustomerServiceTickets();
    }
  }, [days, dispatch]);
  useEffect(() => {
    if (serviceTicketById && serviceTicketById.customer?.id) {
      const customerId = serviceTicketById.customer.id;
      dispatch(readMachinesByCustomerId(customerId));
    }
  }, [dispatch, serviceTicketById]);
  const onDaysChange = (event, newValue) => setDays(newValue);

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };
  const renderDetailsButton = (params) => {
    const handleCopyAndOpenWhatsApp = async (id) => {
      const result = await dispatch(getSvcTktDetailsForWhatsappMessage(id));
      const ticketData = result.payload;
      if (!ticketData) {
        console.error("No ticket data available");
        return;
      }
      const message = ticketData.Message;
      navigator.clipboard
        .writeText(message)
        .then(() => {
          alert(`Message copied:\n\n${message}`);
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    };

    return (
      <strong>
        <Tooltip title="View">
          <Button
            startIcon={<ZoomInIcon />}
            color="primary"
            size="large"
            style={{ fontWeight: "bold" }}
            onClick={() => {
              navigate("/all-service-ticket-view/" + params.row.serviceTicketId);
            }}
          />
        </Tooltip>
        {params.row.status !== "Assign Engineer" ? (
          <Tooltip title="Whatsapp Message">
            <Button
              startIcon={<WhatsAppIcon />}
              color="primary"
              size="medium"
              style={{ fontWeight: "bold" }}
              onClick={() => {
                handleCopyAndOpenWhatsApp(params.row.serviceTicketId);
              }}
            />
          </Tooltip>
        ) : null}
      </strong>
    );
  };
  const columns = [
    { field: "serviceTicketId", headerName: "ID", width: 70 },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.1,
      sortComparator: dateComparator,
      valueGetter: (params) => {
        const dateCreated = params.row?.dateCreated;
        if (dateCreated) {
          return moment(dateCreated).format("DD MMM YYYY");
        }
      },
    },
    {
      field: "closedDate",
      headerName: "Closed Date",
      flex: 0.1,
      valueGetter: (params) => {
        const closedDate = params.row?.closedDate;
        if (closedDate) {
          return moment(closedDate).format("DD MMM YYYY");
        }
      },
      sortComparator: dateComparator,
    },
    { field: "customerName", headerName: "Customer Name", width: 250, flex: 0.2 },
    { field: "ticketCategory", headerName: "Category", width: 250, flex: 0.2 },
    { field: "status", headerName: "Status", width: 150, flex: 0.2 },
    {
      field: "col6",
      headerName: "",
      width: 150,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
      sortable: false,
      filterable: false,
    },
  ];
  const downloadCsv = (e) => {
    e.preventDefault();

    const formattedData = allSvcTickets.data.map((ticket) => {
      return {
        serviceTicketId: ticket.serviceTicketId,
        dateCreated: ticket.dateCreated ? moment(ticket.dateCreated).format("DD MM YYYY") : "",
        closedDate: ticket.closedDate ? moment(ticket.closedDate).format("DD MM YYYY") : "",
        customerName: ticket.customerName,
        ticketCategory: ticket.ticketCategory,
        status: ticket.status,
      };
    });
    const headers = ["Service Ticket Id", "Date Created", "Date Closed", "Customer Name", "Ticket Category", "Status"];
    const keys = ["serviceTicketId", "dateCreated", "closedDate", "customerName", "ticketCategory", "status"];

    exportToCsv(headers, keys, formattedData, `All_Service_Tickets_for_last_${days} days`);
  };

  const onChangeTicketCategory = (event, newValue) => setSelectedCategory(newValue);

  const onChangeTicketStatus = (event, newValue) => setSelectedStatus(newValue);

  const filteredData = allSvcTickets.data.filter((ticket) => {
    return (
      (!selectedCategory || ticket.ticketCategory === selectedCategory) &&
      (!selectedStatus || ticket.status === selectedStatus) &&
      (!searchTerm || Object.values(ticket).join(" ").toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  return (
    <Stack justifyContent="center" sx={{ mx: 2, my: 5 }}>
      <Stack direction={"row"} sx={{ mx: 3 }} justifyContent={"space-between"}>
        <Stack direction={"row"} alignItems="center">
          {userCurrentRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_DATA_OWNER &&
            asyncStorageValues?.configData?.dataOwnerPermissions?.create_edit_tickets && (
              <Button
                startIcon={<AddIcon />}
                sx={{ textTransform: "capitalize", height: 50, ml: 2, mr: 2, width: 110, paddingLeft: "10px", paddingRight: "10px" }}
                variant="contained"
                onClick={() => navigate("/add-service-ticket")}
              >
                Create
              </Button>
            )}
          {userCurrentRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_SERVICE_COORDINATOR && (
            <Button
              startIcon={<AddIcon />}
              sx={{ textTransform: "capitalize", height: 50, ml: 2, mr: 2, width: 110, paddingLeft: "10px", paddingRight: "10px" }}
              variant="contained"
              onClick={() => navigate("/add-service-ticket")}
            >
              Create
            </Button>
          )}
          <Autocomplete
            disablePortal
            id="days"
            options={["30", "60", "90", "180"]}
            onChange={onDaysChange}
            value={days}
            sx={{ width: 150 }}
            renderInput={(params) => <TextField {...params} label="Days" />}
          />
          <TextField
            style={{ marginLeft: 20, width: 250 }}
            label="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm ? (
                    <IconButton edge="end" onClick={handleClearSearch} size="small">
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Autocomplete
            style={{ marginLeft: 20, width: 200 }}
            disablePortal
            id="categorydropdown"
            options={[...new Set(allSvcTickets.data?.map((ticket) => ticket.ticketCategory))].sort()}
            onChange={onChangeTicketCategory}
            value={selectedCategory}
            renderInput={(params) => <TextField {...params} label="Category" variant="outlined" />}
          />
          <Autocomplete
            style={{ marginLeft: 20, width: 200 }}
            disablePortal
            id="statusdropdown"
            options={[...new Set(allSvcTickets.data?.map((ticket) => ticket.status))].sort()}
            onChange={onChangeTicketStatus}
            value={selectedStatus}
            renderInput={(params) => <TextField {...params} label="Status" variant="outlined" />}
          />
        </Stack>
        <Button
          variant="contained"
          sx={{ textTransform: "capitalize", height: 50, ml: 2 }}
          onClick={downloadCsv}
          disabled={allSvcTickets.data.length ? false : true}
        >
          Export to CSV
        </Button>
      </Stack>

      <Box style={{ height: 700, width: "100%", paddingLeft: "2%", paddingTop: 3, marginTop: 8, paddingRight: "1%", overflow: "auto" }}>
        {loading || allSvcTickets.loading ? (
          <CircularProgress color="secondary" sx={{ justifyContent: "center", alignItems: "center" }} />
        ) : (
          <DataGrid
            rows={filteredData}
            getRowId={(row) => row.serviceTicketId}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableColumnMenu
            localeText={{ noRowsLabel: "No tickets." }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default AllSvcTicketForLastXDays;
