import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Search } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useForm, Controller } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import DelIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import {
  Stack,
  Autocomplete,
  TextField,
  Button,
  Typography,
  Box,
  Tooltip,
  CircularProgress,
  Dialog,
  Alert,
  Card,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { readCustomerById, readMachinesByCustomerId } from "../../features/customers/actions";
import {
  addContactToLead,
  updateContact,
  updateLead,
  readAllCustomersByState,
  deleteContactById,
  addMachineToCustomers,
  deleteMachineFromCustomer,
} from "../../features/customers/actions";
import { readAsyncStorageValues } from "../../features/common/actions";
import QRCodeIcon from "@mui/icons-material/QrCode";
import jsPDF from "jspdf";
import { LinearProgress } from "@mui/material";

const CustomerContact = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { machinesByCustomerId, customerContactData, loading } = useSelector((state) => state.customers);
  const [editCustomer, setEditCustomer] = useState(null);
  const { register: addEditMachineFormRegister, handleSubmit: addEditMachineHandleSubmit, reset: resetMachineInput } = useForm();
  const { control, register: editFormRegister, handleSubmit: editHandleSubmit, reset: resetCustomer } = useForm();
  const { register: addEditContactFormRegister, handleSubmit: addEditHandleSubmit, reset } = useForm();
  const [addContact, setAddContact] = useState(null);
  const [addMachine, setAddMachine] = useState(null);
  const [selectedIndianState, setSelectedIndianState] = useState({ label: "Maharashtra (MH)", value: "MH" });
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [warrantyExpiryDate, setwarrantyExpiryDate] = useState(null);
  const [amcExpiryDate, setamcExpiryDate] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMachineModel, setDeleteMachineModel] = useState(null);
  const [deleteCustomerContact, setDeleteCustomerContact] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [downloadProgress, setDownloadProgress] = useState(null);
  const [hideProgress, setHideProgress] = useState(true);
  useEffect(() => {
    dispatch(readAsyncStorageValues());
  }, [dispatch]);
  useEffect(() => {
    dispatch(readMachinesByCustomerId(id));
  }, [dispatch]);
  useEffect(() => {
    dispatch(readCustomerById(id));
  }, [dispatch]);

  const { indianStateDropdownOptions, machineModels } = useSelector((state) => state.common);
  const handleBackClick = () => {
    navigate(-1);
  };
  const inputChangeHandler = (event) => {
    const mobileNumberRegex = /^[0-9+().\-\s]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const yearRegex = /^[1-9]\d{3}$/;
    let id = event.target.id;
    let value = event.target.value;
    if (id === "name") {
      if (!value) {
        setValidationErrors((prevState) => ({ ...prevState, name: "Name is required" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, name: null }));
      }
    } else if (id === "number") {
      if (!mobileNumberRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, number: "Please enter a valid Phone Number" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, number: null }));
      }
    } else if (id === "email") {
      if (!emailRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, email: "Please enter a valid email" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, email: null }));
      }
    } else if (id === "contactNumber") {
      if (!mobileNumberRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, contactNumber: "Please enter a valid Phone Number" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, contactNumber: null }));
      }
    } else if (id === "contactEmail") {
      if (!emailRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, contactEmail: "Please enter a valid email" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, contactEmail: null }));
      }
    } else if (id === "manufacturingYear") {
      if (!yearRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, manufacturingYear: "Please enter a valid year" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, manufacturingYear: null }));
      }
    }
  };

  const editCustomerForm = () => (
    <Box component="form" onSubmit={editHandleSubmit(submitEditForm)} noValidate id="editCustomerForm">
      <Stack rowGap={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            margin="normal"
            required
            fullWidth
            defaultValue={customerContactData.name}
            id="customerName"
            label="Customer Name"
            name="customerName"
            autoFocus
            {...editFormRegister("customerName")}
            error={Boolean(validationErrors.name)}
            helperText={validationErrors.name ? validationErrors.name : ""}
            onChange={inputChangeHandler}
          />
          <Controller
            control={control}
            name="state"
            defaultValue={indianStateDropdownOptions.find((item) => item.label == customerContactData.state) || ""}
            rules={{
              required: "required field",
            }}
            render={({ field: { onChange } }) => (
              <Autocomplete
                disablePortal
                required
                fullWidth
                style={{ marginTop: 8 }}
                options={indianStateDropdownOptions}
                defaultValue={indianStateDropdownOptions.find((item) => item.label == customerContactData.state) || ""}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.label == value.label}
                onChange={(event, item) => onChange(item)}
                renderInput={(params) => <TextField {...params} label="State" />}
              />
            )}
          />
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            margin="normal"
            defaultValue={customerContactData.telephoneNumber}
            fullWidth
            id="number"
            label="Phone Number"
            name="number"
            {...editFormRegister("number")}
            error={Boolean(validationErrors.number)}
            helperText={validationErrors.number ? validationErrors.number : ""}
            onChange={inputChangeHandler}
            style={{ marginTop: 6 }}
          />
          <TextField
            margin="normal"
            defaultValue={customerContactData.emailAddress}
            fullWidth
            id="email"
            label="Email"
            name="email"
            {...editFormRegister("email")}
            error={Boolean(validationErrors.email)}
            helperText={validationErrors.email ? validationErrors.email : ""}
            onChange={inputChangeHandler}
          />
          <TextField
            margin="normal"
            fullWidth
            defaultValue={customerContactData.gstNumber}
            id="gstNumber"
            label="Gst Number"
            name="gstNumber"
            {...editFormRegister("gstNumber")}
          />
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            margin="normal"
            defaultValue={customerContactData.billingAddress}
            fullWidth
            id="billingAddress"
            label="Billing Address"
            name="billingAddress"
            {...editFormRegister("billingAddress")}
            style={{ marginTop: 6 }}
            multiline
            maxRows={10}
          />
          <TextField
            margin="normal"
            fullWidth
            defaultValue={customerContactData.shippingAddress}
            id="shippingAddress"
            label="Shipping Address"
            name="shippingAddress"
            {...editFormRegister("shippingAddress")}
            multiline
            maxRows={10}
            sx={{ minWidth: 540, mr: 4 }}
          />
        </Stack>
        <Stack style={{ flexDirection: "row", justifyContent: "center" }}>
          <Button
            type="button"
            variant="contained"
            sx={{ height: "self", marginRight: 5, width: 100 }}
            onClick={() => {
              setEditCustomer(null);
            }}
          >
            Close
          </Button>
          <Button type="submit" variant="contained" sx={{ height: "self", width: 100 }}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
  const submitAddEditMachineForm = async (data) => {
    if (selectedModel?.id && data.serialNumber.length > 0 && !validationErrors.manufacturingYear) {
      let collection = {};
      if (selectedMachine) collection.machineId = selectedMachine.id;
      collection.customerId = customerContactData.id;
      collection.modelId = selectedModel.id;
      collection.serialNumber = data.serialNumber;
      collection.manufacturingYear = data.manufacturingYear;
      collection.machineModel = selectedModel.id;
      if (amcExpiryDate) collection.amcExpiryDate = amcExpiryDate;
      else if (warrantyExpiryDate) collection.warrantyExpiryDate = warrantyExpiryDate;
      if (selectedMachine) {
        setSelectedMachine(null);
        dispatch(addMachineToCustomers(collection)).then((res) => {
          // dispatch(readCustomerById(id)).then((res) => {});
          dispatch(readMachinesByCustomerId(id)).then((res) => {});
          resetMachineInput(null);
          toast.success(` Machine Updated `, { autoClose: 1000 });
        });
      } else {
        setSelectedMachine(null);
        dispatch(addMachineToCustomers(collection)).then((res) => {
          // dispatch(readCustomerById(id))
          dispatch(readMachinesByCustomerId(id)).then((res) => {});
          resetMachineInput(null);
          toast.success(` Machine Added `, { autoClose: 1000 });
        });
      }

      setAddMachine(null);
      setSelectedModel(null);
      setSelectedMachine(null);
    } else {
      toast.warn("Please fill all the required fields(*)");
    }
  };
  const deleteFunction = async () => {
    if (deleteMachineModel) {
      await dispatch(deleteMachineFromCustomer(deleteMachineModel)).then((res) => {
        dispatch(readMachinesByCustomerId(id)).then((res) => {});
        dispatch(readCustomerById(id)).then((res) => {});
        toast.success(`Machine is deleted`, { autoClose: 1000 });
        setDeleteMachineModel(null);
      });
    } else if (deleteCustomerContact) {
      await dispatch(deleteContactById(deleteCustomerContact));
      dispatch(readCustomerById(id));
      toast.success(`Contact is deleted`, { autoClose: 1000 });
      setDeleteCustomerContact(null);
    }
    setDeleteModal(false);
  };

  const addMachineForm = () => (
    <Box
      component="form"
      onSubmit={addEditMachineHandleSubmit(submitAddEditMachineForm)}
      noValidate
      id="addMachineForm"
      sx={{ width: "800px", margin: "0 auto", paddingRight: 4 }} // Adjust the width as needed
    >
      <Typography variant="h5" fontWeight={600} component="h2" sx={{ mb: 2, textAlign: "center" }}>
        Machine Form
      </Typography>
      <Stack rowGap={2}>
        <Autocomplete
          disablePortal
          required
          fullWidth
          name="machineModel"
          id="machine model"
          options={machineModels}
          getOptionLabel={(option) => option.model}
          isOptionEqualToValue={(option, value) => option.model == value.model}
          defaultValue={machineModels.find((item) => item.model == selectedMachine?.model) || null}
          onChange={(event, item) => setSelectedModel(item)}
          // sx={{ width: 500, height: 50 }}
          renderInput={(params) => <TextField {...params} label="Machine model*" />}
        />
        <TextField
          margin="normal"
          disabled
          value={selectedModel?.manufacturer || ""}
          id="manufacturer"
          label="Manufacturer"
          name="manufacturer"
          // autoFocus
          {...addEditMachineFormRegister("manufacturer")}
        />
        <TextField
          margin="normal"
          //required
          fullWidth
          defaultValue={selectedMachine?.serialNumber || ""}
          id="serialNumber"
          label="Serial number*"
          name="serialNumber"
          InputProps={{
            inputProps: { maxLength: 250 },
          }}
          // autoFocus
          {...addEditMachineFormRegister("serialNumber")}
        />
        <TextField
          margin="normal"
          fullWidth
          defaultValue={selectedMachine?.manufacturingYear || ""}
          id="manufacturingYear"
          label="Manufacturing year"
          name="manufacturingYear"
          InputProps={{
            inputProps: { maxLength: 4 },
          }}
          // autoFocus
          {...addEditMachineFormRegister("manufacturingYear")}
          error={Boolean(validationErrors.manufacturingYear)}
          helperText={validationErrors.manufacturingYear ? validationErrors.manufacturingYear : ""}
          onChange={inputChangeHandler}
        />

        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            minDate={moment(new Date())}
            sx={{
              flex: 1,
              "& .Mui-error": {
                color: "black",
              },
              "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                borderColor: "#C8C8C8",
                borderBlockColor: "#C8C8C8",
                color: "black",
              },
              "& .MuiInputLabel-root": {
                color: "#888888",
              },
              "& .MuiInputBase-input": {
                color: "black",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "black",
              },
            }}
            label="Amc"
            format={"DD MM YYYY"}
            value={moment(selectedMachine?.amcExpiryDate || "")}
            // autoFocus
            onChange={(newValue) => setamcExpiryDate(newValue)}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            minDate={moment(new Date())}
            sx={{
              flex: 1,
              "& .Mui-error": {
                color: "black",
              },
              "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                borderColor: "#C8C8C8",
                borderBlockColor: "#C8C8C8",
                color: "black",
              },
              "& .MuiInputLabel-root": {
                color: "#888888",
              },
              "& .MuiInputBase-input": {
                color: "black",
              },
              "& .MuiInputBase-input::placeholder": {
                color: "black",
              },
            }}
            label="Warranty"
            format={"DD MM YYYY"}
            value={moment(selectedMachine?.warrantyExpiryDate || "")}
            // autoFocus
            onChange={(newValue) => setwarrantyExpiryDate(newValue)}
          />
        </LocalizationProvider>
        <Stack flexDirection={"row"} sx={{ justifyContent: "center", gap: 2 }}>
          <Button
            onClick={() => {
              setAddMachine(null);
              setSelectedMachine(null);
              resetMachineInput(null);
              setSelectedModel(null);
              setValidationErrors((prevState) => ({ ...prevState, manufacturingYear: null }));
            }}
            fullWidth
            variant="contained"
            sx={{ width: 70, height: 40, mr: 4 }}
          >
            Close
          </Button>
          <Button type="submit" fullWidth variant="contained" sx={{ width: 80, height: 40 }}>
            Submit
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
  const submitEditForm = async (data) => {
    if (!validationErrors.name && !validationErrors.number && !validationErrors.email) {
      await dispatch(
        updateLead({
          state: data.state.label,
          id: customerContactData.id,
          telephoneNumber: data.number,
          emailAddress: data.email,
          name: data.customerName,
          billingAddress: data.billingAddress,
          shippingAddress: data.shippingAddress,
          gstNumber: data.gstNumber,
        })
      ).then(() => dispatch(readCustomerById(id)));

      toast.success(`${data.customerName} customer Updated`, { autoClose: 1000 });
      dispatch(readAllCustomersByState({ indianStateCode: selectedIndianState.value }));
      setEditCustomer(null);
    } else {
      toast.warn("Please fill all the required fields(*)");
    }
  };

  const addContactForm = () => (
    <Box component="form" onSubmit={addEditHandleSubmit(submitAddEditContactForm)} noValidate id="addContactForm">
      <Stack rowGap={1}>
        <TextField
          margin="normal"
          //required
          defaultValue={selectedContact?.name || ""}
          fullWidth
          id="contactName"
          label="Person Name*"
          name="contactName"
          textTransform="capitalize"
          // autoFocus
          {...addEditContactFormRegister("contactName")}
          error={Boolean(validationErrors.contactName)}
          helperText={validationErrors.contactName ? validationErrors.contactName : ""}
          onChange={inputChangeHandler}
        />
        <TextField
          margin="normal"
          //required
          fullWidth
          defaultValue={selectedContact?.mobileNumber || ""}
          id="contactNumber"
          label="Phone Number"
          name="contactNumber"
          {...addEditContactFormRegister("contactNumber")}
          error={Boolean(validationErrors.contactNumber)}
          helperText={validationErrors.contactNumber ? validationErrors.contactNumber : ""}
          onChange={inputChangeHandler}
        />
        <TextField
          margin="normal"
          //required
          fullWidth
          defaultValue={selectedContact?.email || ""}
          id="contactEmail"
          label="Email"
          name="contactEmail"
          {...addEditContactFormRegister("contactEmail")}
          error={Boolean(validationErrors.contactEmail)}
          helperText={validationErrors.contactEmail ? validationErrors.contactEmail : ""}
          onChange={inputChangeHandler}
        />
        <Stack flexDirection={"row"} justifyContent={"center"} gap={2}>
          <Button
            fullWidth
            variant="contained"
            sx={{ width: 70, height: 40, mr: 4 }}
            onClick={() => {
              setAddContact(null);
              setSelectedContact(null);
              reset(null);
              setValidationErrors((prevState) => ({ ...prevState, contactEmail: null, contactNumber: null }));
            }}
          >
            Close
          </Button>
          <Button type="submit" fullWidth variant="contained" sx={{ width: 80, height: 40 }}>
            Submit
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
  const submitAddEditContactForm = async (data) => {
    if (data.contactName && !validationErrors.contactEmail && !validationErrors.contactNumber) {
      setSelectedContact(null);
      const dispatchAction = selectedContact ? updateContact : addContactToLead;

      await dispatch(
        dispatchAction({
          id: selectedContact ? selectedContact.id : id,
          mobileNumber: data.contactNumber,
          email: data.contactEmail,
          name: data.contactName,
        })
      ).then(() => {
        reset(null);
        const actionVerb = selectedContact ? "Updated" : "Added";
        toast.success(`Contact ${actionVerb}`, { autoClose: 1000 });
        dispatch(readCustomerById(id));
      });

      setAddContact(null);
    } else {
      toast.warn("Please fill all the required fields(*)");
    }
  };
  const handleEdit = (id, field, value) => {
    machinesByCustomerId?.data?.map(
      (machine) => (
        setSelectedModel(machineModels.find((item) => item.model === machine.model)), setSelectedMachine(machine)
        // setAddMachine(true)
      )
    );
  };
  const generateQRCodeImage = async (data) => {
    const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=${encodeURIComponent(data)}`;
    const response = await fetch(qrCodeApiUrl);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };
  const handleQRCodeClick = async () => {
    try {
      for (const machine of machinesByCustomerId?.data || []) {
        const data = `
                Model: ${machine.model}
                Serial number: ${machine.serialNumber}
                Manufacturing Year: ${machine.manufacturingYear}
                AMC: ${machine.amcExpiryDate ? moment(machine.amcExpiryDate).format("DD MMM YYYY") : ""}
                Warranty: ${machine.warrantyExpiryDate ? moment(machine.warrantyExpiryDate).format("DD MMM YYYY") : ""}
            `;
        const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=${encodeURIComponent(data)}`;

        const response = await fetch(qrCodeApiUrl);
        if (!response.ok) {
          console.error("Failed to generate QR code.");
          continue;
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        const custName = customerContactData.name;
        downloadLink.download = `${custName}_qr_code_machine_.png`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(blobUrl);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const generatePDF = async () => {
    const doc = new jsPDF();

    const qrCodeWidth = 76.2;
    const qrCodeHeight = 76.2;
    const qrCodesPerRow = 2;
    const rowsPerPage = 3;
    const marginLeft = 10;
    const marginTop = 10;
    const spacingX = 95;
    const spacingY = 95;
    const totalQRCodeCount = machinesByCustomerId.data.length;
    let currentQRCodeCount = 0;

    setHideProgress(false);

    for (let i = 0; i < machinesByCustomerId.data.length; i += qrCodesPerRow * rowsPerPage) {
      if (i !== 0) {
        doc.addPage();
      }

      for (let row = 0; row < rowsPerPage; row++) {
        for (let col = 0; col < qrCodesPerRow; col++) {
          const currentIndex = i + row * qrCodesPerRow + col;
          if (currentIndex < machinesByCustomerId.data.length) {
            const machine = machinesByCustomerId.data[currentIndex];
            const formattedData = `
  Model: ${machine.model}
  Serial Number: ${machine.serialNumber}
  Manufacturing Year: ${machine.manufacturingYear}
  AMC: ${machine.amcExpiryDate}
  Warranty: ${machine.warrantyExpiryDate}
            `;

            const qrCodeImageUrl = await generateQRCodeImage(formattedData);
            const x = marginLeft + col * spacingX;
            const y = marginTop + row * spacingY;
            doc.text(`QR Code for Service Ticket\n`, x, y - 1);
            doc.text(`${machine.model}\n${machine.serialNumber}\n`, x, y + qrCodeHeight + 5);
            doc.addImage(qrCodeImageUrl, "PNG", x, y, qrCodeWidth, qrCodeHeight);

            currentQRCodeCount++;
            const progress = (currentQRCodeCount / totalQRCodeCount) * 100;
            setDownloadProgress(progress);
          }
        }
      }
    }

    const custName = customerContactData.name;

    doc.save(`${custName}_qr_codes_machines_${moment().format("DD-MM-YYYY_HH:mm")}.pdf`);

    setDownloadProgress(100);
    setHideProgress(true);
  };

  const deleteContactForm = async (data) => {
    await dispatch(deleteContactById(data));
    dispatch(readCustomerById(id));
    toast.success(`Contact is deleted`, { autoClose: 1000 });
  };

  const renderDetailsButton = (params) => {
    return (
      <strong>
        <Tooltip title="Download QR">
          <Button onClick={handleQRCodeClick}>
            <QRCodeIcon />
          </Button>
        </Tooltip>

        <Tooltip title="Edit">
          <Button
            sx={{ cursor: "pointer", color: "#5D8AA8" }}
            onClick={() => {
              handleEdit();
              setAddMachine(true);
            }}
          >
            <EditIcon />
          </Button>
        </Tooltip>
      </strong>
    );
  };

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

  const columns = [
    { field: "manufacturer", headerName: "Manufacturer", width: 350, flex: 0.4 },
    { field: "model", headerName: "Model", width: 190, flex: 0.4 },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      width: 160,
      flex: 0.5,
      sortable: true,
      sortComparator: (v1, v2) => {
        return v1.localeCompare(v2, undefined, { numeric: true });
      },
    },
    {
      field: "amcExpiryDate",
      headerName: "AMC",
      width: 150,
      flex: 0.5,
      sortable: true,
      sortComparator: dateComparator,
      valueGetter: (params) => {
        if (params.row.amcExpiryDate) {
          return moment(params.row.amcExpiryDate).format("DD MMM YYYY");
        } else {
          return "";
        }
      },
    },
    {
      field: "warrantyExpiryDate",
      headerName: "Warranty",
      width: 150,
      flex: 0.5,
      sortable: true,
      sortComparator: dateComparator,
      valueGetter: (params) => {
        if (params.row.warrantyExpiryDate) {
          return moment(params.row.warrantyExpiryDate).format("DD MMM YYYY");
        } else {
          return "";
        }
      },
    },
    {
      field: "col6",
      headerName: "",
      width: 150,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
      sortable: false,
      filterable: false,
    },
  ];

  const customeStyle = {
    flex: 1,
    padding: 2,
    mb: 1,
    border: "1px solid #ddd",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  };
  const handleSearchChange = (event) => setSearchTerm(event.target.value);
  const handleClearSearch = () => setSearchTerm("");
  return (
    <div>
      <Stack sx={{ mb: 1 }}>
        <Card sx={{ p: 3, boxShadow: "none" }}>
          {editCustomer ? (
            <>{editCustomerForm()}</>
          ) : (
            <Stack>
              <Stack sx={[customeStyle]}>
                <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" fontWeight={600}>
                    {customerContactData.name}
                  </Typography>
                  <Button sx={{ height: "self" }} variant="contained" onClick={() => setEditCustomer(true)}>
                    {editCustomer ? "Save" : "Edit"}
                  </Button>
                </Stack>
                <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2">
                  <span style={{ fontWeight: "bold" }}>State:</span> {customerContactData.state}
                </Typography>

                <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2">
                  <span style={{ fontWeight: "bold" }}>Phone Number:</span> {customerContactData.telephoneNumber}
                </Typography>

                <Typography sx={{ display: "inline" }} variant="body2">
                  <span style={{ fontWeight: "bold" }}>Email:</span> {customerContactData.emailAddress}
                </Typography>

                <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2">
                  <span style={{ fontWeight: "bold" }}>Billing Address:</span> {customerContactData.billingAddress}
                </Typography>

                <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2">
                  <span style={{ fontWeight: "bold" }}>Shipping Address:</span> {customerContactData.shippingAddress}
                </Typography>

                <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2">
                  <span style={{ fontWeight: "bold" }}>Gst Number:</span> {customerContactData.gstNumber}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Card>
      </Stack>

      {!editCustomer ? (
        <Stack flexDirection="row" sx={{ flex: 1, gap: 1, flexWrap: "wrap" }}>
          <Stack sx={{ flex: 1, minWidth: 270, maxWidth: 480 }}>
            <Stack mb={1} direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h5" fontWeight={600} mb={1} ml={7}>
                Contacts
              </Typography>
              <Tooltip title="Add contact" onClick={() => setAddContact(true)}>
                <Button sx={{ height: "self", mr: 4 }} variant="contained" color="primary">
                  Add
                </Button>
              </Tooltip>
            </Stack>
            <Card variant="outlined" sx={{ p: 2, marginLeft: 3 }}>
              {addContact ? (
                <>{addContactForm()}</>
              ) : (
                <>
                  {loading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <>
                      {customerContactData?.contacts?.length ? (
                        customerContactData?.contacts?.map((contact) => (
                          <Stack
                            key={contact.id} // Assuming you have a unique identifier for each contact
                            flexDirection={"row"}
                            alignItems={"flex-start"}
                            sx={customeStyle}
                          >
                            <Stack sx={{ flex: 2 }}>
                              <Typography variant="body2">
                                <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>Person Name:</span> {contact.name}
                              </Typography>

                              <Typography variant="body2">
                                <span style={{ textTransform: "capitalize", fontWeight: "bold" }}>Phone Number:</span>{" "}
                                {contact.mobileNumber}
                              </Typography>

                              <Typography variant="body2">
                                <span style={{ fontWeight: "bold" }}>Email:</span> {contact.email}
                              </Typography>
                            </Stack>

                            <Stack direction={"row"} sx={{ flex: 0.3 }}>
                              <Tooltip title="Edit contact">
                                <EditIcon
                                  sx={{ cursor: "pointer", mr: 2, color: "#5D8AA8" }}
                                  onClick={() => {
                                    setSelectedContact(contact);
                                    setAddContact(true);
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title="Delete contact">
                                <DelIcon
                                  sx={{ cursor: "pointer", color: "#5D8AA8" }}
                                  onClick={() => {
                                    setDeleteModal(true);
                                    setDeleteCustomerContact(contact);
                                  }}
                                />
                              </Tooltip>
                            </Stack>
                          </Stack>
                        ))
                      ) : (
                        <Stack>
                          <Typography style={{ marginLeft: 12 }}>No contacts.</Typography>
                        </Stack>
                      )}
                    </>
                  )}
                </>
              )}
            </Card>
          </Stack>
          {addMachine ? (
            <>{addMachineForm()}</>
          ) : (
            <>
              <Stack sx={{ flex: 1, minWidth: 250 }}>
                <Stack direction={"row"} gap={1} alignItems={"center"} paddingRight={3} flexWrap="wrap">
                  <Typography variant="h5" fontWeight={600} ml={0.7} paddingRight={15}>
                    Machines
                  </Typography>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    placeholder="Search..."
                    endAdornment={
                      <InputAdornment position="end">
                        {searchTerm ? (
                          <IconButton edge="end" onClick={handleClearSearch} size="small">
                            <CloseIcon />
                          </IconButton>
                        ) : (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        )}
                      </InputAdornment>
                    }
                    sx={{ minWidth: 300, maxWidth: 600, height: 35, marginLeft: "280px" }}
                  />
                  <Stack height={35}>
                    <Button
                      startIcon={<DownloadIcon />}
                      sx={{ height: "self" }}
                      autoCapitalize="none"
                      variant="contained"
                      onClick={generatePDF}
                      disabled={!hideProgress}
                    >
                      Download QR
                    </Button>
                    {downloadProgress !== null && !hideProgress && (
                      <Box>
                        <LinearProgress variant="determinate" value={downloadProgress} />
                        <Typography variant="body2" color="textSecondary">{`${downloadProgress.toFixed(2)}%`}</Typography>
                      </Box>
                    )}
                  </Stack>
                  <Button
                    sx={{ height: "self", alignSelf: "center" }}
                    autoCapitalize="none"
                    variant="contained"
                    onClick={() => setAddMachine(true)}
                  >
                    ADD
                  </Button>
                </Stack>

                <Box sx={{ height: 650, paddingRight: 4.5, paddingTop: 3.5 }}>
                  <DataGrid
                    // checkboxSelection={true}
                    rows={machinesByCustomerId.data.filter((item) =>
                      Object.values(item).join(" ").toLowerCase().includes(searchTerm.toLowerCase())
                    )}
                    columns={columns}
                    pageSize={5}
                    localeText={{ noRowsLabel: "No Data." }}
                    style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.2)", marginLeft: 5 }}
                    onSelectRow={selectedMachine}
                  />
                </Box>
              </Stack>
            </>
          )}
          <Dialog open={deleteModal} alignSelf="center">
            <Alert
              open={deleteModal}
              // onClose={handleCloseDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div id="alert-dialog-title">Confirm Action</div>
              <div id="alert-dialog-description">
                Do you want to delete{" "}
                {deleteMachineModel
                  ? `this machine (${deleteMachineModel.model})`
                  : deleteCustomerContact
                  ? `this contact (${deleteCustomerContact.name})`
                  : null}{" "}
                ?
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={() => {
                    setDeleteModal(false);
                    setDeleteMachineModel(null);
                    setDeleteCustomerContact(null);
                  }}
                >
                  No
                </Button>
                <Button onClick={deleteFunction}>Yes</Button>
              </div>
            </Alert>
          </Dialog>
          <ToastContainer />
        </Stack>
      ) : null}
      {/* </Stack> */}
    </div>
  );
};

export default CustomerContact;
