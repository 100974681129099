import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Autocomplete, TextField, Button, Stack, InputAdornment, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { getServiceTicketsByUserId } from "../../features/servicetickets/actions";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { readAsyncStorageValues } from "../../features/common/actions";
import { getAppUsers } from "../../features/users/actions";
const MyTickets = () => {
  const dispatch = useDispatch();
  const { allSvcTickets, serviceTicketsByUserId } = useSelector((state) => state.serviceTickets);
  const { appUsersList } = useSelector((state) => state.users);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const { userInfo } = useSelector((state) => state.auth);
  const { asyncStorageValues } = useSelector((state) => state.common);
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().add(2, "months").toDate());
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(getAppUsers());
  }, [dispatch]);

  useEffect(() => {
    if (appUsersList.data) {
      const loggedInUser = appUsersList.data.find((user) => user.userName === userInfo?.username);
      if (loggedInUser) setUserId(loggedInUser.userId);
    }
  }, [appUsersList, userInfo]);

  useEffect(() => {
    if (userId) {
      const options = {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        userId: userId,
      };
      dispatch(getServiceTicketsByUserId({ options, customers: asyncStorageValues?.customers || [] }));
    }
  }, [startDate, endDate]);

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const renderDetailsButton = (params) => {
    return (
      <strong>
        {/* <Tooltip title="View">
                    <Button
                        startIcon={<ZoomInIcon />}
                        color="primary"
                        size="large"
                        style={{ fontWeight: "bold" }}
                        onClick={() => {
                            navigate("/all-service-ticket-view/" + params.row.serviceTicketId);
                        }}
                    />
                </Tooltip> */}
      </strong>
    );
  };
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.1,
      sortComparator: dateComparator,
      valueGetter: (params) => {
        const dateCreated = params.row?.dateCreated;
        if (dateCreated) {
          return moment(dateCreated).format("DD MMM YYYY");
        }
      },
    },
    {
      field: "closeDate",
      headerName: "Closed Date",
      flex: 0.1,
      valueGetter: (params) => {
        const closedDate = params.row?.closeDate;
        return closedDate ? moment(closedDate).format("DD MMM YYYY") : "-";
      },
      sortComparator: dateComparator,
    },
    { field: "customerName", headerName: "Customer Name", width: 250, flex: 0.2 },
    { field: "ticketCategory", headerName: "Category", width: 250, flex: 0.2 },
    { field: "status", headerName: "Status", width: 150, flex: 0.2 },
    {
      field: "col6",
      headerName: "",
      width: 150,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
      sortable: false,
      filterable: false,
    },
  ];

  const handleStartDateChange = (date) => {
    setStartDate(date.toDate());
  };

  const handleEndDateChange = (date) => {
    setEndDate(date.toDate());
  };

  return (
    <Stack justifyContent="center" sx={{ mx: 2, my: 5 }}>
      <Stack direction={"row"} sx={{ mx: 3 }} justifyContent={"space-between"}>
        <Stack direction={"row"} alignItems="center">
          <TextField
            style={{ marginLeft: 20, width: 250, marginRight: 200 }}
            label="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm ? (
                    <IconButton edge="end" onClick={handleClearSearch} size="small">
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              value={moment(startDate)}
              onChange={handleStartDateChange}
              label="Start Date"
              format={"DD MMM YYYY"}
              disableFuture
              sx={{ width: 200, paddingRight: 5 }}
            />
            <DatePicker
              value={moment(endDate)}
              onChange={handleEndDateChange}
              label="End Date"
              format={"DD MMM YYYY"}
              disableFuture
              sx={{ width: 200, paddingRight: 5 }}
            />
          </LocalizationProvider>
        </Stack>
      </Stack>

      <Box style={{ height: 700, width: "100%", paddingLeft: "2%", paddingTop: 3, marginTop: 8, paddingRight: "1%", overflow: "auto" }}>
        {serviceTicketsByUserId.loading ? (
          <CircularProgress color="secondary" sx={{ justifyContent: "center", alignItems: "center" }} />
        ) : (
          <DataGrid
            rows={serviceTicketsByUserId.data.filter((ticket) => {
              return !searchTerm || Object.values(ticket).join(" ").toLowerCase().includes(searchTerm.toLowerCase());
            })}
            getRowId={(row) => row.id}
            columns={columns}
            // pageSize={5}
            rowsPerPageOptions={[5]}
            disableColumnMenu
            localeText={{ noRowsLabel: "No tickets." }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default MyTickets;
