import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../app/api";

export const readAsyncStorageValues = createAsyncThunk("common/readAsyncStorageValues", async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.get(`/serviceapi/readAsyncStorageValues`);
    const machineModels = data?.machineModels;
    const indianStateDropdownOptions = Object.entries(data?.allTypes?.STATES).map(([key, value]) => ({
      label: value,
      value: key,
    }));

    const indianStateDropdownOptionsCodeOnly = Object.entries(data?.allTypes?.STATES).map(([key, value]) => ({
      label: key,
      value: key,
    }));

    return { asyncStorageValues: data, indianStateDropdownOptions, indianStateDropdownOptionsCodeOnly, machineModels };
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const getAllTemplates = createAsyncThunk("templates/getAllTemplates", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.get("/serviceapi/getAllTemplates", payload);
    const formattedTemplateList = response?.data
      .map((item) => ({
        ...item,
        sections: item.sections.map((section, index) => ({ ...section, index })),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
    return { ...response, data: [...formattedTemplateList] };
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const getStatesWithServiceTickets = createAsyncThunk("common/getStatesWithServiceTickets", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.get("/serviceapi/getStatesWithServiceTickets", payload);
    const formattedData = response.data.map((item) => {
      const label = item.split("(")[0].trim();
      const value = item.split("(")[1]?.replace(")", "").trim() || "";
      return { label, value };
    });
    return formattedData;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const getStatesWithCustomers = createAsyncThunk("common/getStatesWithCustomers", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.get("/serviceapi/getStatesWithCustomers");
    const formattedData = response.data.map((item) => {
      const label = item.split("(")[0].trim();
      const value = item.split("(")[1]?.replace(")", "").trim() || "";
      return { label, value };
    });
    return formattedData;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
