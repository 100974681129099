import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector, useDispatch } from "react-redux";

import Header from "./components/Header";
import Login from "./screens/Login";
import Profile from "./screens/Profile";
import User from "./screens/Users";
import Home from "./screens/Home";
import Last12MonthsDashboard from "./screens/Last12MonthsDashboard";
import ServiceHoursAllStatesLast4Years from "./components/ServiceHoursAllStatesLast4Years";
import ProtectedRoute from "./routing/ProtectedRoute";
import Customers from "./screens/Customers";
import CustomerContact from "./screens/CustomerContact";
import Reports from "./screens/Reports";
import Sidebar from "./components/Sidebar";
import AllServiceTickets from "./screens/AllServiceTickets";
import MachineModel from "./screens/MachineModel";
import AddEditMachineModel from "./screens/MachineModel/AddEditMachineModel";
import SalesCalls from "./screens/SalesCalls";
import AddEditNewTemplates from "./screens/Templates/AddEditNewTemplates";
import NotFoundPage from "./screens/NotFound";
import LoadingIndicator from "./screens/LoadingPage";

import { readAsyncStorageValues } from "./features/common/actions";

import { ToastContainer } from "react-toastify";
import SalesCallDetailView from "./screens/SalesCalls/SalesCallDetailView";
import AllSvcTicketForLastXDays from "./screens/AllSvcTicketForLastXDays";
import MyTickets from "./screens/MyTickets"
import Templates from "./screens/Templates";
import ViewServiceTicket from "./screens/AllServiceTickets/ViewServiceTicket";
import CreateServiceTicket from "./screens/AllServiceTickets/CreateServiceTicket";
// import "./App.css";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["Karla"],
    },
    palette: {
      primary: {
        main: "#5D8AA8",
      },
      secondary: {
        main: "#DAA520",
      },
    },
  });

  function MainContent() {
    const dispatch = useDispatch();
    const { userInfo, userCurrentRole } = useSelector((state) => state.auth);
    const { asyncStorageValues, loading } = useSelector((state) => state.common);
    useEffect(() => {
      if (userInfo && Object.keys(userInfo).length) dispatch(readAsyncStorageValues());
    }, [dispatch, userInfo]);

    const location = useLocation();
    const mainStyles = { paddingLeft: location.pathname !== "/login" ? "230px" : "0" };

    return (
      <main style={mainStyles}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            {userCurrentRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_REGIONAL_SALES_MANAGER && (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard/last-12-months" element={<Last12MonthsDashboard />} />
                <Route path="/dashboard/last-4-years" element={<ServiceHoursAllStatesLast4Years />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/users" element={<User />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/customer-contact/:id" element={<CustomerContact />} />
                <Route path="/AllServiceTickets" element={<AllServiceTickets />} />
                <Route path="/AllSvcTicketsForLastXdays" element={<AllSvcTicketForLastXDays />} />
              </>
            )}

            {userCurrentRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_NATIONAL_SERVICE_MANAGER && (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard/last-12-months" element={<Last12MonthsDashboard />} />
                <Route path="/dashboard/last-4-years" element={<ServiceHoursAllStatesLast4Years />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/users" element={<User />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/AllServiceTickets" element={<AllServiceTickets />} />
                <Route path="/AllSvcTicketsForLastXdays" element={<AllSvcTicketForLastXDays />} />
              </>
            )}
            {userCurrentRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_SERVICE_COORDINATOR && (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard/last-12-months" element={<Last12MonthsDashboard />} />
                <Route path="/dashboard/last-4-years" element={<ServiceHoursAllStatesLast4Years />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/users" element={<User />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/AllServiceTickets" element={<AllServiceTickets />} />
                <Route path="/AllSvcTicketsForLastXdays" element={<AllSvcTicketForLastXDays />} />
                <Route path={"/all-service-ticket-view/:serviceTicketId"} element={<ViewServiceTicket />} />
                <Route path={"/add-service-ticket"} element={<CreateServiceTicket />} />
                <Route path={"/edit-service-ticket" + "/:id"} element={<AddEditMachineModel />} />
                <Route path={"/MyTickets"} element={<MyTickets />} />
              </>
            )}


            {userCurrentRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_DATA_OWNER && (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard/last-12-months" element={<Last12MonthsDashboard />} />
                <Route path="/dashboard/last-4-years" element={<ServiceHoursAllStatesLast4Years />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/users" element={<User />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/customer-contact/:id" element={<CustomerContact />} />
                <Route path="/AllServiceTickets" element={<AllServiceTickets />} />
                <Route path="/AllSvcTicketsForLastXdays" element={<AllSvcTicketForLastXDays />} />
                <Route path="/machine-model" element={<MachineModel />} />
                <Route path={"/add-machine-model"} element={<AddEditMachineModel />} />
                <Route path={"/edit-machine-model" + "/:id"} element={<AddEditMachineModel />} />
                <Route path="/templates" element={<Templates />} />
                <Route path={"/add-template"} element={<AddEditNewTemplates />} />
                <Route path={"/edit-template" + "/:id"} element={<AddEditNewTemplates />} />
                <Route path={"/all-service-ticket-view/:serviceTicketId"} element={<ViewServiceTicket />} />
              </>
            )}
            {userCurrentRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_DATA_OWNER && asyncStorageValues?.configData?.dataOwnerPermissions?.create_edit_tickets && (
              <>
                <Route path={"/add-service-ticket"} element={<CreateServiceTicket />} />
                <Route path={"/edit-service-ticket" + "/:id"} element={<CreateServiceTicket />} />
              </>
            )}
            <Route path="/sales-calls" element={<SalesCalls />} />
            <Route path="/sales-calls-details" element={<SalesCallDetailView />} />
          </Route>
          <Route path="*" element={loading ? <LoadingIndicator /> : <NotFoundPage />} />
        </Routes>
      </main>
    );
  }
  const drawerWidth = 240;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Sidebar drawerWidth={drawerWidth} />
        <Header />
        <MainContent />
        <ToastContainer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
