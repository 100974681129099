import {
  Box,
  Typography,
  Paper,
  Stack,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Autocomplete,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  readServiceTicket,
  updateServiceTicket,
  readServiceTicketMessagesByServiceTicketId,
  addTextMessageToServiceTicket,
} from "../../../features/servicetickets/actions";
import { readAsyncStorageValues } from "../../../features/common/actions";
import { getCustomersWithServiceTickets } from "../../../features/servicetickets/actions";
import { readCustomerById, readMachinesByCustomerId } from "../../../features/customers/actions";
import { getAppUsers } from "../../../features/users/actions";
import Timelogs from "../../Timelog/Timelogs";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const ViewServiceTicket = () => {
  const dispatch = useDispatch();

  const { serviceTicketById, customersWithServiceTickets, serviceTicketMessages } = useSelector((state) => state.serviceTickets);
  const { customerContactData, machinesByCustomerId } = useSelector((state) => state.customers);
  const { asyncStorageValues } = useSelector((state) => state.common);
  const { appUsersList } = useSelector((state) => state.users);
  const { userCurrentRole } = useSelector((state) => state.auth);
  const [showDialog, setShowDialog] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { serviceTicketId } = useParams();
  const [serviceCategories, setServiceCategories] = useState([]);
  const [salesUsersList, setSalesUsersList] = useState([]);
  const [termsOfServiceList, setTermsOfServiceList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [filteredMachines, setFilteredMachines] = useState([]);
  const [serviceEngineerList, setServiceEngineerList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editFormData, setEditFormData] = useState({
    status: "",
    serviceTicketId: "",
    machineId: "",
    salesPersonId: "",
    ticketCategory: "",
    customerId: "",
    assignees: [],
    otherUsers: [],
    scheduledDate: null,
    serviceTicketPaymentType: "",
    description: "",
  });

  useEffect(() => {
    dispatch(readServiceTicket(serviceTicketId));
    dispatch(readServiceTicketMessagesByServiceTicketId(serviceTicketId));
  }, [dispatch, serviceTicketId]);

  useEffect(() => {
    if (serviceTicketById?.customer?.id) dispatch(readMachinesByCustomerId(serviceTicketById.customer.id));
    setFilteredMachines([]);
  }, [dispatch, serviceTicketById]);
  useEffect(() => {
    if (machinesByCustomerId.data) {
      const formattedMachines =
        machinesByCustomerId.data.map((machine) => ({
          ...machine,
          label: formatMachineLabel(machine),
        })) || [];
      setFilteredMachines(formattedMachines);
    } else {
      setFilteredMachines([]);
    }
  }, [machinesByCustomerId]);

  useEffect(() => {
    if (serviceTicketById?.customer?.id) {
      const customerId = serviceTicketById.customer.id;
      dispatch(readCustomerById(customerId));
    }
  }, [dispatch, serviceTicketById]);
  useEffect(() => {
    if (asyncStorageValues?.allTypes?.SERVICE_CATEGORY) {
      const categories = Object.values(asyncStorageValues.allTypes.SERVICE_CATEGORY);
      setServiceCategories(categories);
    }
  }, [asyncStorageValues]);
  useEffect(() => {
    if (asyncStorageValues?.allTypes?.SERVICE_TICKET_PAYMENT_TYPE) {
      const termsOfService = Object.values(asyncStorageValues.allTypes.SERVICE_TICKET_PAYMENT_TYPE);
      setTermsOfServiceList(termsOfService);
    }
  }, [asyncStorageValues]);
  useEffect(() => {
    if (asyncStorageValues?.allTypes?.SERVICE_TICKET_STATUS) {
      const status = Object.values(asyncStorageValues.allTypes.SERVICE_TICKET_STATUS);
      setStatusList(status);
    }
  }, [asyncStorageValues]);
  useEffect(() => {
    dispatch(getCustomersWithServiceTickets());
    dispatch(getAppUsers());
    dispatch(readAsyncStorageValues());
  }, [dispatch]);

  useEffect(() => {
    if (serviceTicketById && serviceTicketById.customer) {
      const customerId = serviceTicketById.customer.id;
      dispatch(readMachinesByCustomerId(customerId));
    }
  }, [dispatch, serviceTicketById]);
  const formatMachineLabel = (machine) => {
    const { manufacturer, model, serialNumber, amcExpiryDate, warrantyExpiryDate } = machine;
    let serviceStatus;

    if (warrantyExpiryDate && new Date(warrantyExpiryDate) > new Date()) {
      serviceStatus = `Warranty ${moment(warrantyExpiryDate).format("DD MMM YY")}`;
    } else if (amcExpiryDate && new Date(amcExpiryDate) > new Date()) {
      serviceStatus = `AMC ${moment(amcExpiryDate).format("DD MMM YY")}`;
    } else {
      serviceStatus = "PAID SERVICE";
    }

    return `${manufacturer}, ${model} [${serialNumber}, ${serviceStatus}]`;
  };
  const customerName =
    customersWithServiceTickets.data.find((user) => user.customerId === serviceTicketById?.customer?.id)?.customerName || "";
  const salesPersonName = asyncStorageValues.appUsers.find((user) => user.userId === serviceTicketById?.salesPerson?.id)?.name || "";
  const machine = machinesByCustomerId?.data?.find((machine) => machine.id === serviceTicketById?.machine?.id);
  const serviceStatus = (() => {
    if (machine) {
      const { warrantyExpiryDate, amcExpiryDate } = machine;
      if (warrantyExpiryDate && new Date(warrantyExpiryDate) > new Date()) {
        return `Warranty ${moment(warrantyExpiryDate).format("DD MMM YY")}`;
      } else if (amcExpiryDate && new Date(amcExpiryDate) > new Date()) {
        return `AMC ${moment(amcExpiryDate).format("DD MMM YY")}`;
      } else {
        return "PAID SERVICE";
      }
    }
  })();
  const model = machine ? `${machine.manufacturer}, ${machine.model} [${machine.serialNumber}, ${serviceStatus}]` : "";
  let serviceEngineer = "Unknown";
  if (asyncStorageValues && asyncStorageValues.appUsers && serviceTicketById?.assignees?.length > 0) {
    const engineerNames = serviceTicketById.assignees
      .map((assignee) => {
        const user = asyncStorageValues.appUsers.find((user) => user.userId === assignee.id);
        return user?.name || "Unknown";
      })
      .filter((name) => name !== "Unknown");

    serviceEngineer = engineerNames.length > 0 ? engineerNames.join(", ") : "Unknown";
  }

  useEffect(() => {
    if (asyncStorageValues?.allTypes && appUsersList.data.length) {
      const roles = asyncStorageValues.allTypes.USER_ROLES;
      const salesUsers = appUsersList.data
        .filter((obj) => (obj.userRole === roles.ROLE_REGIONAL_SALES_MANAGER || obj.userRole === roles.ROLE_SALES_PERSON) && obj.enabled)
        .sort((a, b) => a.name.localeCompare(b.name));

      if (userCurrentRole === roles.ROLE_REGIONAL_SALES_MANAGER) {
        setSalesUsersList(salesUsers.filter((user) => asyncStorageValues.assignedSaleEngineers.includes(user.userId)));
      } else {
        setSalesUsersList(salesUsers);
      }

      const serviceUsers = appUsersList.data
        .filter((obj) => obj.userRole === roles.ROLE_SERVICE_ENGINEER || (obj.userRole === roles.ROLE_SERVICE_COORDINATOR && obj.enabled))
        .sort((a, b) => a.name.localeCompare(b.name));

      if (userCurrentRole === roles.ROLE_SERVICE_ENGINEER) {
        setServiceEngineerList(serviceUsers.filter((user) => asyncStorageValues.assignedSaleEngineers.includes(user.userId)));
      } else {
        setServiceEngineerList(serviceUsers);
      }
    }
  }, [appUsersList, asyncStorageValues, userCurrentRole]);

  useEffect(() => {
    if (serviceTicketById) {
      setEditFormData({
        status: serviceTicketById.status,
        serviceTicketId: serviceTicketById.id,
        machineId: serviceTicketById.machine ? serviceTicketById.machine.id : "",
        salesPersonId: serviceTicketById.salesPerson ? serviceTicketById.salesPerson.id : "",
        ticketCategory: serviceTicketById.ticketCategory,
        customerId: serviceTicketById.customer ? serviceTicketById.customer.id : "",
        assignees: serviceTicketById.assignees ? serviceTicketById.assignees.map((assignee) => assignee.id) : [],
        otherUsers: serviceTicketById.otherUsers ? serviceTicketById.otherUsers.map((user) => user.id) : [],
        scheduledDate: serviceTicketById.scheduledDate ? moment(serviceTicketById.scheduledDate) : null,
        serviceTicketPaymentType: serviceTicketById.serviceTicketPaymentType,
        description: serviceTicketById.description,
        serviceEngineer: serviceEngineer,
        salesPerson: salesPersonName,
        model: model,
      });
    }
  }, [serviceTicketById, serviceTicketId, customerName, serviceEngineer, salesPersonName, model]);

  const customStyle = {
    p: 2,
    border: "1px solid #ddd",
    borderRadius: "10px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 4px 4px rgba(0,0,0,0.03)",
    mb: 1,
  };

  const handleNoteChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 50000) {
      setNewNote(inputValue);
      setErrorMsg("");
    } else {
      setErrorMsg(`Character limit reached (50000 characters max)`);
    }
  };

  const handleCancel = () => {
    setShowDialog(false);
    setNewNote("");
  };
  const handleAddNote = async () => {
    if (newNote.length && newNote.trim()) {
      const postData = {
        serviceTicketId,
        message: newNote,
      };
      dispatch(addTextMessageToServiceTicket(postData)).then((res) =>
        dispatch(readServiceTicketMessagesByServiceTicketId(serviceTicketId))
      );
      setNewNote("");
      setShowDialog(false);
    } else setErrorMsg("*Please enter notes.");
  };

  const minutes_to_hhmm = (numberOfMinutes) => {
    var hh =
      numberOfMinutes.years() * (365 * 24) + numberOfMinutes.months() * (30 * 24) + numberOfMinutes.days() * 24 + numberOfMinutes.hours();
    var mm = numberOfMinutes.minutes();
    return hh + "h " + mm + " m";
  };
  const isDataLoaded = serviceTicketById && asyncStorageValues;
  const state = customerContactData.state;
  const filteredNotes = serviceTicketMessages.data?.filter((message) => message.type === "text").reverse();
  const handleEditSave = () => {
    const selectedEngineer = serviceEngineerList.find((e) => e.name === editFormData.serviceEngineer);

    const assigneeId = editFormData.serviceEngineer
      .map((name) => {
        const engineer = serviceEngineerList.find((e) => e.name === name);
        return engineer ? engineer.userId : null;
      })
      .filter((id) => id !== null);

    const selectedSalesPerson = salesUsersList.find((e) => e.name === editFormData.salesPerson);
    const salesPersonId = selectedSalesPerson ? selectedSalesPerson.userId : null;
    const machine = filteredMachines.find((e) => e.label === editFormData.model);
    const machineId = machine ? machine.id : null;
    const updatedData = {
      status: editFormData.status,
      serviceTicketId: editFormData.serviceTicketId,
      machineId: machineId,
      salesPersonId: salesPersonId,
      ticketCategory: editFormData.ticketCategory,
      customerId: editFormData.customerId,
      assignees: assigneeId,
      otherUsers: editFormData.otherUsers,
      scheduledDate: editFormData.scheduledDate ? moment(editFormData.scheduledDate).toISOString() : null,
      serviceTicketPaymentType: editFormData.serviceTicketPaymentType,
      description: editFormData.description,
    };
    dispatch(updateServiceTicket(updatedData))
      .then(() => {
        setEditMode(false);
        return dispatch(readServiceTicket(serviceTicketId));
      })
      .catch((error) => {
        console.error("Error updating service ticket:", error);
      });
  };

  return (
    <div>
      <Box sx={{ mx: 3, marginY: 2, marginLeft: "3%" }}>
        {isDataLoaded ? (
          <>
            <Paper sx={{ borderRadius: 2, mb: 1.5, p: 4, boxShadow: "0 10px 20px rgba(0,0,0,0.16), 0 6px 6px rgba(0,0,0,0.20)" }}>
              <Stack flexDirection="row" justifyContent={"space-between"} sx={{ flex: 1, flexWrap: "wrap" }}>
                {editMode ? (
                  <>
                    <Stack sx={{ flex: 2, mb: 3, gap: 1, marginRight: 1 }}>
                      <TextField label="Customer Name" name="customerName" value={customerName} disabled fullWidth />
                      <Autocomplete
                        options={serviceCategories}
                        getOptionLabel={(option) => option}
                        value={editFormData.ticketCategory}
                        onChange={(event, newValue) =>
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            ticketCategory: newValue,
                          }))
                        }
                        renderInput={(params) => <TextField {...params} label="Select Category" required />}
                      />
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Scheduled Date"
                          value={editFormData.scheduledDate}
                          format={"DD MMM YYYY"}
                          onChange={(newValue) => {
                            setEditFormData({ ...editFormData, scheduledDate: newValue });
                          }}
                          minDate={moment()}
                          renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                      </LocalizationProvider>
                      <Autocomplete
                        multiple
                        options={serviceEngineerList}
                        getOptionLabel={(option) => option.name}
                        value={serviceEngineerList.filter((e) => editFormData.serviceEngineer.includes(e.name))}
                        onChange={(event, newValue) => {
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            serviceEngineer: newValue.map((engineer) => engineer.name),
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Assigned to" required />}
                        isOptionEqualToValue={(option, value) => option.userId === value?.userId}
                      />
                      <TextField
                        label="Description"
                        name="description"
                        value={editFormData.description || ""}
                        onChange={(e) =>
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            description: e.target.value,
                          }))
                        }
                        fullWidth
                      />
                    </Stack>
                    <Stack sx={{ flex: 2, mb: 3, gap: 1 }}>
                      <Autocomplete
                        options={statusList}
                        getOptionLabel={(option) => option}
                        value={editFormData.status}
                        onChange={(event, newValue) =>
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            status: newValue,
                          }))
                        }
                        renderInput={(params) => <TextField {...params} label="Status" required />}
                      />
                      <Autocomplete
                        options={salesUsersList}
                        getOptionLabel={(option) => option.name}
                        value={salesUsersList.find((e) => e.name === editFormData.salesPerson) || null}
                        onChange={(event, newValue) => {
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            salesPerson: newValue ? newValue.name : "",
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Sales Person" required />}
                        isOptionEqualToValue={(option, value) => option.userId === value.userId}
                      />
                      <Autocomplete
                        options={filteredMachines}
                        getOptionLabel={(option) => option.label}
                        value={filteredMachines.find((e) => e.label === editFormData.model) || null}
                        onChange={(event, newValue) => {
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            model: newValue ? newValue.label : "",
                            machineId: newValue ? newValue.id : "",
                          }));
                        }}
                        renderInput={(params) => <TextField {...params} label="Model" required />}
                      />

                      <Autocomplete
                        options={termsOfServiceList}
                        getOptionLabel={(option) => option}
                        value={editFormData.serviceTicketPaymentType}
                        onChange={(event, newValue) =>
                          setEditFormData((prevFormData) => ({
                            ...prevFormData,
                            serviceTicketPaymentType: newValue,
                          }))
                        }
                        renderInput={(params) => <TextField {...params} label="Terms Of Service" required />}
                      />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        sx={{ height: "fit-content", marginLeft: 0.8, textTransform: "capitalize" }}
                        onClick={handleEditSave}
                      >
                        Save
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        sx={{ height: "fit-content", textTransform: "capitalize" }}
                        onClick={() => setEditMode(false)}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack sx={{ flex: 2, mb: 3, gap: 1 }}>
                      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
                        Service Ticket : {serviceTicketById.id}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Customer Name : </span>
                        {customerName},{state}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Ticket Category : </span>
                        {serviceTicketById.ticketCategory}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Scheduled Date : </span>
                        {serviceTicketById.scheduledDate ? moment(serviceTicketById.scheduledDate).format("DD MMM YYYY") : null}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Assigned to : </span>
                        {serviceEngineer}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Description : </span>
                        {serviceTicketById.description}
                      </Typography>
                    </Stack>
                    <Stack sx={{ flex: 2, mb: 3, gap: 1, marginTop: "3%" }}>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Status : </span>
                        {serviceTicketById.status}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Sales Person : </span>
                        {salesPersonName}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Model : </span>
                        {model}
                      </Typography>
                      <Typography>
                        <span style={{ fontWeight: "bold" }}>Terms of Service : </span>
                        {serviceTicketById.serviceTicketPaymentType}
                      </Typography>
                    </Stack>
                    {userCurrentRole === asyncStorageValues?.allTypes?.USER_ROLES?.ROLE_DATA_OWNER &&
                      asyncStorageValues?.configData?.dataOwnerPermissions?.create_edit_tickets && (
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => setEditMode(true)}
                          sx={{ height: "fit-content", textTransform: "capitalize" }}
                        >
                          Edit
                        </Button>
                      )}
                  </>
                )}
              </Stack>
            </Paper>

            <Grid container spacing={2}>
              <Grid item xs={10} md={6}>
                <Paper sx={{ borderRadius: 2, mb: 0.8, p: 4, boxShadow: "0 10px 20px rgba(0,0,0,0.16), 0 6px 6px rgba(0,0,0,0.20)" }}>
                  <Stack mb={1} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" sx={{ mb: 0.8 }}>
                      Notes
                    </Typography>
                    <Tooltip title="Add note">
                      <Button
                        sx={{ height: "self", textTransform: "capitalize" }}
                        variant="contained"
                        color="primary"
                        onClick={() => setShowDialog(true)}
                      >
                        Add
                      </Button>
                    </Tooltip>
                  </Stack>
                  <Dialog open={showDialog} onClose={handleCancel}>
                    <DialogTitle>Add note</DialogTitle>
                    <DialogContent>
                      <TextField
                        value={newNote}
                        label="Note"
                        multiline
                        onChange={handleNoteChange}
                        error={errorMsg && !newNote.length}
                        helperText={errorMsg || `${newNote.length}/50000`}
                        sx={{ minWidth: 350, m: 2, maxWidth: 900, maxHeight: 700 }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCancel}>Cancel</Button>
                      <Button sx={{ height: "self", textTransform: "capitalize" }} variant="contained" onClick={handleAddNote}>
                        Add
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <List>
                    {filteredNotes?.map((note, index) => (
                      <React.Fragment key={index}>
                        {note.type === asyncStorageValues.allTypes.TYPE_OF_CONTENT.TEXT ? (
                          <Paper>
                            <Stack minWidth={120} justifyContent={"space-between"} sx={customStyle}>
                              <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2" fontWeight={400}>
                                  {note?.postedDate || note?.dateCreated
                                    ? moment(note?.postedDate ? note.postedDate : note.dateCreated).format("DD MMM HH:mm")
                                    : "N.A"}{" "}
                                </Typography>
                                <Typography sx={{ display: "inline" }} variant="body2">
                                  {note.postedUser.name || "-"}
                                </Typography>
                              </Stack>
                              <Typography sx={{ display: "inline" }}>{note.message}</Typography>
                            </Stack>
                          </Paper>
                        ) : null}
                        {index < filteredNotes.length - 1 && <Divider component="li" />}
                      </React.Fragment>
                    ))}
                  </List>
                </Paper>
              </Grid>
              <Grid item xs={10} md={6}>
                <Timelogs ticketId={serviceTicketId} />
              </Grid>
            </Grid>
          </>
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Box>
    </div>
  );
};

export default ViewServiceTicket;
