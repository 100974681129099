import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../app/api";

export const getServiceTicketsForTheLastXDays = createAsyncThunk(
  "serviceTickets/getServiceTicketsByStatusList",
  async (days, { rejectWithValue }) => {
    try {
      const { data } = await api.post(`/serviceapi/getServiceTicketsForTheLastXDays?days=${days}`, {});
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceTicketsByStatusList = createAsyncThunk(
  "serviceTickets/getServiceTicketsByStatusList",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/serviceapi/ getServiceTicketsByStatusList`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCustomersWithServiceTickets = createAsyncThunk(
  "customers/getCustomersWithServiceTickets",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/serviceapi/getCustomersWithServiceTickets`);

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const readServiceTicket = createAsyncThunk("serviceTickets/readServiceTicket", async (serviceTicketId, { rejectWithValue }) => {
  try {
    const response = await api.get(`serviceapi/readServiceTicket?id=${serviceTicketId}`);
    // console.log("service tkt", response)
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const readServiceTicketMessagesByServiceTicketId = createAsyncThunk(
  "serviceTickets/readServiceTicketMessagesByServiceTicketId",
  async (serviceTicketId, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`serviceapi/readServiceTicketMessagesByServiceTicketId?id=${serviceTicketId}`);
      // console.log("Messages", data)
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createServiceTicket = createAsyncThunk("servicetickets/createServiceTicket", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/serviceapi/createServiceTicket", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const updateServiceTicket = createAsyncThunk("servicetickets/updateServiceTicket", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/serviceapi/updateServiceTicket", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const addTextMessageToServiceTicket = createAsyncThunk(
  "servicetickets/addTextMessageToServiceTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.post("/serviceapi/addTextMessageToServiceTicket", payload);
      return response;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const readServiceTimeLog = createAsyncThunk("servicetickets/readServiceTimeLog", async (serviceTicketId, { rejectWithValue }) => {
  try {
    const { data } = await api.get(`serviceapi/readServiceTimeLog?id=${serviceTicketId}`);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const getSvcTktDetailsForWhatsappMessage = createAsyncThunk(
  "servicetickets/getSvcTktDetailsForWhatsappMessage",
  async (serviceTicketId, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`serviceapi/getSvcTktDetailsForWhatsappMessage?serviceTicketId=${serviceTicketId}`);
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getServiceTicketsByUserId = createAsyncThunk(
  "servicetickets/getServiceTicketsByUserId",
  async (payload, { rejectWithValue }) => {
    try {
      const url = `/serviceapi/getServiceTicketsByUserId?userId=${payload.options.userId}&startDate=${payload.options.startDate}&endDate=${payload.options.endDate}`;
      const { data } = await api.get(url);
      if (data) {
        const modified = data
          .map((item) => {
            const customer = payload.customers.find((customer) => customer.id === item.customer?.id);
            return { ...item, customerName: customer?.customerName || "" };
          })
          .sort((a, b) => b.id - a.id);
        return modified;
      }
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
